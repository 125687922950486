/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
 query getFacetData($search: String = "", $filter: ProductAttributeFilterInput) {
   products(search: $search, filter: $filter) {
     items {
       __typename
       uid
       id
       sku
       artist
       name
       bestsellers
       short_description {
        html
       }
       description {
        html
       }
       stock_status
       new
       sale
       size
       rating_summary
       review_count
       meta_title
       meta_description
       ... on ConfigurableProduct {
        configurable_options {
          id
          uid
          attribute_uid
          label
          values {
            default_label
            label
            uid
          }
        }
      }
       categories {
        __typename
         name
         url_path
         breadcrumbs {
            __typename
            category_name
            category_url_path
         }
       }
       image {
        url
        position
        disabled
        label
      }
       media_gallery {
         url
       }
       url_key
       url_rewrites {
         url
       }
       price_range {
         maximum_price {
           final_price {
             currency
             value
           }
           regular_price {
             currency
             value
           }
         }
         minimum_price {
           final_price {
             currency
             value
           }
           regular_price {
             currency
             value
           }
         }
       }
       ... on GroupedProduct {
         items {
           product {
             sku
           }
         }
       }
     }
     page_info {
       current_page
       page_size
       total_pages
     }
     total_count
   }
 }
`;

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { placeOrderCommand, getModuleCommand } from '~/modules/checkout/composables/useMakeOrder/commands/placeOrderCommand';
import useCart from '~/modules/checkout/composables/useCart';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import axios from 'axios';
import type { UseMakeOrderErrors, UseMakeOrderInterface } from './useMakeOrder';

/**
 * Allows making an order from a cart.
 *
 * See the {@link UseMakeOrderInterface} for a list of methods and values available in this composable.
 */
export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart } = useCart();
  const context = useContext();

  const make = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.debug('useMakeOrder.make');
    let placedOrder = null;
    try {
      loading.value = true;
      placedOrder = await placeOrderCommand.execute(context, cart.value.id, params);
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }

    return placedOrder;
  };

  const makeExpressCheckoutOrder = async (confirmationToken: any) : Promise<PlaceOrderOutput | null> => {
    const VSF_MAGENTO_BASE_URL = process.env.VSF_MAGENTO_BASE_URL || 'https://m.modernsquared.com/';
    const MAKE_ORDER_REST_ENDPOINT = 'rest/V1/stripe/payments/place_order';

    let response;
    try {
      response = await axios({
        url: `${VSF_MAGENTO_BASE_URL}${MAKE_ORDER_REST_ENDPOINT}`,
        method: 'POST',
        data: {
          confirmationToken,
        },
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return response;
    } catch {
      response = null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getModuleConfiguration = async (params?: ComposableFunctionArgs<{}>): Promise<any> => {
    Logger.debug('useMakeOrder.getModuleConfiguration');
    let config = null;
    try {
      loading.value = true;
      config = await getModuleCommand.execute(context);
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }

    return config;
  };

  return {
    makeExpressCheckoutOrder,
    getModuleConfiguration,
    make,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useMakeOrder';
export default useMakeOrder;

import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import jwtDecode from 'jwt-decode';

import getBetterProductCustomerReviews from '~/modules/review/composables/useReview/graphql/getBetterProductCustomerReviews.gql';

export type CustomerProductReviewParams = {
  pageSize: number;
  currentPage: number;
};

export const getBetterProductCustomerReviewsCommand = {
  execute: async (context: VsfContext, params?: any) => {
    const token = context.$magento.config.state.getCustomerToken();
    const decoded : { uid: number } = jwtDecode(token);
    if (decoded) {
      // eslint-disable-next-line no-param-reassign
      params.customerId = decoded.uid;
    }
    Logger.debug('[Magento] load product review based on:', { params });

    const { data }: { data: any } = await context.$magento.api.customQuery({
      query: getBetterProductCustomerReviews,
      queryVariables: params,
    });

    Logger.debug('[Result]:', { data });
    return data.mpBprGetReview;
  },
};

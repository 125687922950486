import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

import createBetterProductReview from '~/modules/review/composables/useReview/graphql/createBetterProductReview.gql';

export type CreateProductReviewParams = {
  productId: number;
  input: {
    mp_bpr_images?: string;
    title: string;
    detail: string;
    nickname: string;
    avg_value: string;
  };
};

export const createProductReviewCommand = {
  execute: async (context: VsfContext, params: CreateProductReviewParams) => {
    Logger.debug('[Magento] creating product review with params:', { params });

    const { data }: { data: any } = await context.$magento.api.customQuery({
      query: createBetterProductReview,
      queryVariables: params,
    });

    Logger.debug('[Result]:', { data });
    return data.mpBprCreateReview;
  },
};

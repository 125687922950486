import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

import upvoteBetterProductReview from '~/modules/review/composables/useReview/graphql/upvoteBetterProductReview.gql';

export type UpvoteProductReviewParams = {
  reviewId: number;
};

export const upvoteBetterProductReviewCommand = {
  execute: async (context: VsfContext, params: UpvoteProductReviewParams) => {
    Logger.debug('[Magento] upvoting product review with params:', { params });

    const { data }: { data: any } = await context.$magento.api.customQuery({
      query: upvoteBetterProductReview,
      queryVariables: params,
    });

    Logger.debug('[Result]:', { data });
    return data.mpBprUpvoteReview;
  },
};

export default `
query mfAutoRelatedProducts ($ruleId: Int $pageSize: Int $currentPage: Int) {
  mfAutoRelatedProducts(
      ruleId: $ruleId
      pageSize: $pageSize
      currentPage: $currentPage
 ){
     total_count
   items{
      products{
        uid
        id
        name
        sku
        url_key
        image {
          url
        }
        media_gallery {
          url
        }
        price_range {
          minimum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
      }
     category_ids{
       category_id
     }
          customer_group_ids{
       group_id
     }
          store_ids{
       store_id
     }
   }
 }
}
`;

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import type { Maybe, CustomerOrder, CustomerOrdersQuery } from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import type { UseUserOrderErrors, UseUserOrderInterface, UseUserOrderSearchParams } from './useUserOrder';

const CUSTOMER_ORDERS_QUERY = `query CustomerOrders {
  customer {
    orders {
      items {
        id
        created_at
        status
        number
        order_date
        total {
          base_grand_total {
            currency
            value
          }
          subtotal {
            currency
            value
          }
        }
        billing_address {
          city
          company
          country_code
          firstname
          lastname
          postcode
          region
          region_id
          street
          telephone
        }
        shipping_address {
          city
          company
          country_code
          firstname
          lastname
          postcode
          region
          region_id
          street
          telephone
        }
        payment_methods {
          name
          type
        }
        items {
          id
          product_sku
          product_name
          quantity_ordered
          quantity_canceled
          quantity_invoiced
          quantity_refunded
          quantity_returned
          quantity_shipped
          status
          product {
            id
            uid
            image {
              url
              position
              disabled
              label
            }
            reviews {
            items {
              average_rating
              nickname
              text
            }
          }
          }
          discounts {
            label
            amount {
              currency
              value
            }
          }
          entered_options {
            label
            value
          }
          product_sale_price {
            currency
            value
          }
          selected_options {
            label
            value
          }
        }
      }
    }
  }
}
`;

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      const { data } = await app.$vsf.$magento.api.customerOrders(params, params?.customQuery ?? null, params?.customHeaders);

      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const loadSuccessOrder = async ({ order_number }: { order_number: string }): Promise<Maybe<CustomerOrder | any>> => {
    loading.value = true;
    const data = await search({
      filter: { number: { eq: order_number } },
      customQuery: { query: CUSTOMER_ORDERS_QUERY },
    });
    const order = data?.items[0];
    if (order?.items) {
      const promises = order.items.map(async (item) => {
        const query = `query GetProductImage($url_key: String!) {
          products(filter: { url_key: { eq: $url_key } }) {
            items {
              thumbnail {
                url
              }
            }
          }
        }`;
        const response: any = await app.$vsf.$magento.api.customQuery({
          query,
          queryVariables: { url_key: item.product_url_key },
        });
        // eslint-disable-next-line no-param-reassign
        item.thumbnail = response?.data?.products?.items.length ? response?.data?.products?.items[0].thumbnail : undefined;

        return item;
      });

      try {
        const updatedItems = await Promise.all(promises);
        order.items = updatedItems;
        loading.value = false;
      } catch {
        loading.value = false;
      }
    }
    return order || undefined;
  };

  const loadCustomersOrders = async () => {
    let results = null;

    try {
      loading.value = true;

      const { data } : { data : CustomerOrdersQuery } = await app.$vsf.$magento.api.customQuery({ query: CUSTOMER_ORDERS_QUERY });
      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    loadCustomersOrders,
    search,
    loadSuccessOrder,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';

/* eslint-disable quote-props */
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import { getProductRelatedCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductRelatedCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { ProductInterface } from '~/modules/GraphQL/types';
import type {
  ProductDetails, ProductList, UseProductErrors, UseProductInterface,
} from './useProduct';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  });

  const context = useContext();

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;
      products = await getProductDetailsCommand.execute(context, searchParams);

      error.value.getProductDetails = null;
    } catch (err) {
      error.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getRelatedProducts = async (productID: number = null, section: string = 'trending') => {
    const ruleIDs = {
      'also-like': 1,
      'trending': 6,
      'recently-viewed': 9,
    };

    let items : Array<any> = [];
    try {
      const params = {
        ruleId: productID ? ruleIDs['also-like'] : ruleIDs[section],
        pageSize: 15,
        currentPage: 1,
      };

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!productID) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        params['productId'] = productID;
      }

      items = await getProductRelatedCommand.execute(context, params);
    } catch (err) {
      error.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    const MAX_DISPLAYED_ITEMS = 8;
    return [...items].slice(0, MAX_DISPLAYED_ITEMS);
  };

  const addProductToViewedReport = async (product: { id: number }, visitor_id = null) => {
    const guestUserQuery = visitor_id ? ` visitor_id: "${visitor_id}"` : '';
    const customQuery = `
    mutation {
      addViewedProductsToReport(product_id: "${product.id}"${guestUserQuery}) {
        message
      }
    }
  `;
    await context.app.$vsf.$magento.api.customQuery({ query: customQuery });
  };

  const getViewedProductsFromReport = async (visitor_id = null) => {
    const queryHeader = visitor_id ? `getViewedProductsFromReport(visitor_id: "${visitor_id}")` : 'getViewedProductsFromReport';
    const customQuery = `
    query {
      ${queryHeader} {
        total_count
        currentPage
        pageSize
        totalPages
        items
        {
        id
        name
        sku
        url_key
        orientation
        color
        image {
          url
        }
        media_gallery {
          url
        }
        price_range {
          minimum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        }
      }
    }
  `;
    const { data } : { data : any } = await context.app.$vsf.$magento.api.customQuery({ query: customQuery });
    const MAX_DISPLAYED_ITEMS = 8;
    const recentlyViewedProducts : Array<any> = data?.getViewedProductsFromReport?.items || [];
    return recentlyViewedProducts.slice(0, MAX_DISPLAYED_ITEMS);
  };

  const loadTrendingProducts = async (SKUsList: string[]): Promise<ProductList | null> => {
    const searchParams: GetProductSearchParams = {
      pageSize: 10,
      currentPage: 1,
      filter: {
        sku: {
          in: SKUsList,
        },
      },
    };

    const trendingProducts = await getProductDetails(searchParams);
    return trendingProducts;
  };

  const getProductPath = (product: ProductInterface) => {
    if (!product) return '/';
    return `/${product?.url_rewrites?.[0]?.url ?? product.url_key}`;
  };

  return {
    getViewedProductsFromReport,
    addProductToViewedReport,
    getProductList,
    getProductDetails,
    getProductPath,
    getRelatedProducts,
    loadTrendingProducts,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;

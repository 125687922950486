export default `
query mpBprGetReview ($productId: Int!, $pageSize: Int!, $currentPage: Int!, $sort: SortInput) {
  mpBprGetReview(
    action: "get_by_productId"
    productId: $productId
    pageSize: $pageSize
    currentPage: $currentPage
    sort: $sort
  ) {
    total_count
    pageInfo {
      currentPage
      endPage
      hasNextPage
      hasPreviousPage
      pageSize
      startPage
    }
    items {
      avg_value
      created_at
      customer_id
      detail
      detail_id
      entity_id
      entity_pk_value
      mp_bpr_helpful
      mp_bpr_images
      mp_bpr_recommended_product
      mp_bpr_verified_buyer
      nickname
      reply_content
      reply_created_at
      reply_enabled
      reply_nickname
      review_id
      status_id
      title
    }
  }
}
`;

import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

import getBetterProductReview from '~/modules/review/composables/useReview/graphql/getBetterProductReview.gql';

export type CustomerProductReviewParams = {
  pageSize: number;
  currentPage: number;
};

export const getBetterProductReviewCommand = {
  execute: async (context: VsfContext, params?: any) => {
    Logger.debug('[Magento] load product review based on:', { params });

    const { data }: { data: any } = await context.$magento.api.customQuery({
      query: getBetterProductReview,
      queryVariables: params,
    });

    Logger.debug('[Result]:', { data });
    return data.mpBprGetReview;
  },
};

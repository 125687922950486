/* eslint-disable no-extra-boolean-cast */
import { UseContextReturn } from '~/types/core';

import productRelated from '~/modules/catalog/category/stores/graphql/productRelated.gql';
import productsTrending from '~/modules/catalog/category/stores/graphql/productsTrending.gql';

export const getProductRelatedCommand = {
  execute: async (context: UseContextReturn, params: any) => {
    const { data }: { data: any } = await context.app.$vsf.$magento.api.customQuery({
      query: !!params?.productId ? productRelated : productsTrending,
      queryVariables: params,
    });

    return data?.mfAutoRelatedProducts?.items?.[0]?.products ?? [];
  },
};

export default `
mutation mpBprCreateReview ($productId: Int!, $input: ReviewInput!) {
  mpBprCreateReview(
    productId: $productId
    input: $input
  ) {
    avg_value
    review_id
    created_at
    customer_id
    detail
    detail_id
    entity_id
    entity_pk_value
    mp_bpr_images
    mp_bpr_recommended_product
    mp_bpr_verified_buyer
    nickname
    status_id
    title
  }
}
`;

import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

import getBetterProductReviewRating from '~/modules/review/composables/useReview/graphql/getBetterProductReviewRating.gql';

export type GetProductReviewRatingParams = {
  productId: number;
};

export const getBetterProductReviewRatingCommand = {
  execute: async (context: VsfContext, params: GetProductReviewRatingParams) => {
    Logger.debug('[Magento] fetching product review rating summary with params:', { params });

    const { data }: { data: any } = await context.$magento.api.customQuery({
      query: getBetterProductReviewRating,
      queryVariables: params,
    });

    Logger.debug('[Result]:', { data });
    return data.mpBprProductRatingSummary;
  },
};

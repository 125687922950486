import axios from 'axios';

export declare type BlogArticlesParams = {
  id?: number;
  pageSize?: number;
  page?: number;
};

export const loadBlogArticlesCommand = {
  execute: async (params: BlogArticlesParams): Promise<any> => {
    const DEFAULT_PAGE_SIZE = 6;
    const DEFAULT_PAGE = 1;

    const pageSize = params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE;
    const page = params.page ? params.page : DEFAULT_PAGE;

    const ENDPOINT = 'blog-articles';
    const PARAMS = params.id ? `/${params.id}` : false;
    const QUERY = `?pagination[pageSize]=${pageSize}&pagination[page]=${page}`;

    const editedEndpoint = PARAMS ? ENDPOINT + PARAMS : ENDPOINT + QUERY;

    const CMSBaseURL = process.env.VSF_CMS_STRAPI_URL;
    const client = axios.create({
      baseURL: CMSBaseURL,
    });
    // eslint-disable-next-line prefer-destructuring
    const VSF_STRAPI_ACCESS_TOKEN = process.env.VSF_STRAPI_ACCESS_TOKEN;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    client.defaults.headers.common['Authorization'] = `Bearer ${VSF_STRAPI_ACCESS_TOKEN}`;

    const url = `${CMSBaseURL}/${editedEndpoint}`;

    let response;
    try {
      response = await client.get(url);
    } catch {
      response = null;
    }
    return response?.data;
  },
};

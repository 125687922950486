<template>
  <SfTopBar
    class="layout-topbar ms-container"
  >
    <template #center>
      <div
        v-if="showContentMessage"
        class="content-message text-ms-secondary body-5"
      >
        <p>
          {{ section.content.promotion.message }}
        </p>
      </div>
    </template>
    <template #right>
      <div class="topbar-links">
        <nuxt-link
          v-for="(link, index) in section.content.links"
          :key="index"
          ref="links"
          :to="pageRoute(link.page)"
          :target="link.showIcon ? '_blank' : ''"
          class="topbar-link"
          @keydown.native="
            keyboardNavigate($event, index, section.content.links)
          "
        >
          <span>{{ link.title }}</span>
          <svg
            v-if="link.showIcon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.4231 0H9.80769C9.48908 0 9.23077 0.2583 9.23077 0.576923C9.23077 0.895546 9.48908 1.15385 9.80769 1.15385H13.0302L5.55359 8.63054C5.32829 8.85585 5.32829 9.22108 5.55359 9.44638C5.77889 9.67169 6.14415 9.67169 6.36946 9.44638L13.8462 1.96974V5.1923C13.8462 5.51093 14.1045 5.76922 14.4231 5.76922C14.7417 5.76922 15 5.51093 15 5.1923V0.576923C15 0.2583 14.7417 0 14.4231 0ZM3.65385 1.53846C1.63588 1.53846 0 3.17435 0 5.19231V11.3462C0 13.3642 1.63588 15 3.65385 15H9.80769C11.8257 15 13.4615 13.3642 13.4615 11.3462V7.5C13.4615 7.18138 13.2032 6.92308 12.8846 6.92308C12.566 6.92308 12.3077 7.18138 12.3077 7.5V11.3462C12.3077 12.7268 11.1884 13.8462 9.80769 13.8462H3.65385C2.27313 13.8462 1.15385 12.7268 1.15385 11.3462V5.19231C1.15385 3.81159 2.27313 2.69231 3.65385 2.69231H7.5C7.81862 2.69231 8.07692 2.43401 8.07692 2.11538C8.07692 1.79676 7.81862 1.53846 7.5 1.53846H3.65385Z"
              fill="#5C5C5C"
            />
          </svg>
        </nuxt-link>
      </div>
    </template>
  </SfTopBar>
</template>

<script>
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfTopBar } from '@storefront-ui/vue';

import { keyboardNavigation } from '~/utilities';

const DEFAULT_TOPBAR_SECTION = {
  content: {
    promotion: {
      message:
              'Become an Insider: Be the first to receive expert decorating ideas and 20% off your first order',
      is_displayed: true,
      end_date: null,
    },
    links: [
      {
        title: 'm²mag',
        page: 'inside-modern-squared/blog',
        showIcon: false,
      },
      {
        title: 'professionals',
        page: 'professionals/trade-program',
        showIcon: true,
      },
    ],
  },
};

export default defineComponent({
  components: {
    SfTopBar,
  },
  props: {
    cmsSection: {
      type: Object,
      default: () => (DEFAULT_TOPBAR_SECTION),
    },
  },
  setup(props) {
    const { app } = useContext();
    const pageRoute = (page) => app.localeRoute({ name: page });

    const section = props.cmsSection || DEFAULT_TOPBAR_SECTION;
    return {
      section,
      pageRoute,
    };
  },
  data() {
    return {};
  },
  computed: {
    showContentMessage() {
      return (
        !!this.section.content?.promotion?.message
        && this.section.content?.promotion?.is_displayed
      );
    },
  },
  methods: {
    keyboardNavigate(event, index, table) {
      const actions = {
        37: () => {
          if (index !== 0) {
            index -= 1;
            this.$refs.links[index].$el.focus();
          }
        },
        39: () => {
          if (index !== table.length - 1) {
            index += 1;
            this.$refs.links[index].$el.focus();
          }
        },
      };
      keyboardNavigation(event, index, table, actions);
    },
  },
});
</script>
<style lang="scss" scoped>
.layout-topbar {
  width: 100%;
  max-width: $app-max-width;
  display: flex;
  position: relative;
  z-index: 1001;
  @include responsive-styles(padding-top, 20px, 20px, 14px, 12px);
  @include responsive-styles(padding-bottom, 25px, 25px, 12px, 12px);
}
::v-deep .sf-top-bar__container {
  margin: 0 !important;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0 !important;
}
.content-message {
  display: flex;
  height: auto;
  p {
    @include responsive-styles(font-size, 14px, 14px, 14px, 15px);
    @include responsive-styles(line-height, normal, 131%, normal, normal);
    color: $color-secondary;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: 0 !important;
  }
}
.topbar-links {
  @include responsive-styles(display, none, none, flex, flex);
  align-items: center;
  gap: 20px;
  .topbar-link {
    min-height: 20px;
    display: flex;
    align-items: center;
    color: $color-secondary;
    @include responsive-styles(font-size, 14px, 14px, 14px, 16px);
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    svg {
      margin-left: 10px;
    }
  }
}
</style>

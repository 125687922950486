export default `
query mpBprProductRatingSummary($productId: Int!) {
  mpBprProductRatingSummary(productId: $productId) {
    averageRating
    ratingSpread {
      star
      count
    }
  }
}
`;
